import { find } from 'lodash';
var ROUTES = {
  auth: {
    login: 'https://voicebrief.app/signin',
    //:accessToken?
    signup: '/signup',
    confirm: '/confirm/:email',
    recover: '/recover/:recoveryUID?',
    resendActivationLink: '/console/resendActivationLink',
    activation: '/auth/verify-email/:activationUID',
    forgottenPassword: '/forgotten-password/'
  },
  business: {
    redirect: '/',
    getChannelsPage: "/",
    getChannelPage: function getChannelPage(channelUUID) {
      return "/channels/".concat(channelUUID);
    },
    createBrief: function createBrief(channelUUID) {
      return "/briefs/create?channelUUID=".concat(channelUUID);
    },
    getBriefPage: function getBriefPage(briefUUID) {
      return "/briefs/".concat(briefUUID);
    },
    getChannelSettingsPage: function getChannelSettingsPage(channelUUID) {
      return "/channels/".concat(channelUUID, "/settings");
    },
    getChannelAnaliticsPage: function getChannelAnaliticsPage(channelUUID) {
      return "/channels/".concat(channelUUID, "/analitics");
    }
  },
  user: '/user/:userUUID',
  getUserPage: function getUserPage(user) {
    return '/user/' + (user === null || user === void 0 ? void 0 : user.uuid);
  },
  channel: '/user/:userUUID/channels/:channelUUID',
  getChannelsPage: function getChannelsPage(userUUID) {
    return "/user/".concat(userUUID, "/channels");
  },
  getChannelPage: function getChannelPage(userUUID, channelUUID) {
    return "/user/".concat(userUUID, "/channels/").concat(channelUUID);
  },
  createChannelPage: function createChannelPage(user) {
    return "/user/".concat(user.uuid, "/channels/create");
  },
  brief: '/channels/:channelUUID/brief'
};
export default ROUTES;
export var AUTH_ROUTES = ['signin', 'confirm', 'signup', 'activation', 'newpassword'];
export var isAnAuthRoute = function isAnAuthRoute(route) {
  var isAuth = find(AUTH_ROUTES, function (authRoute) {
    return route.includes(authRoute);
  });
  return isAuth;
};